<template>
    <modal name="picture-modal"
        :adaptive="true"
        :scrollable="true"
        :height="'auto'"
        >
        <div class="container-fluid p-0">
            <div class="card">
                <div class="card-header py-3 bg-white border-0">
                    <a @click="$modal.hide('picture-modal')" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    <h1 class="fredoka-font ls-3 fw-700 text-current-eling font-lg mb-0">Elingway</h1>
                </div>
                <div class="card-body py-5 py-2">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="avatar-upload"  v-show="!edit">
                                <div class="text-center p-2">
                                    <label for="avatar">
                                        <img width="200" :src="files.length ? files[0].url : user.photo" />
                                    </label>
                                </div>
                                <div class="text-center p-2">
                                    <file-upload
                                    extensions="jpg,jpeg,png"
                                    accept="image/png,image/jpeg,image/jpg"
                                    name="avatar"
                                    class="btn btn-current"
                                    :drop="!edit"
                                    v-model="files"
                                    @input-filter="inputFilter"
                                    ref="upload" required>
                                    Upload Picture
                                    </file-upload>
                                    <button @click="savePicture()" class="btn btn-success ml-2" v-if="changed">Save Picture</button>
                                </div>
                            </div>

                            <div class="avatar-edit" v-show="files.length && edit">
                                <div class="avatar-edit-image" v-if="files.length">
                                    <cropper
                                        class="cropper"
                                        ref="cropper"
                                        :src="files[0].url"
                                        :stencil-props="{
                                            handlers: {},
                                            movable: false,
                                            scalable: false,
                                            aspectRatio: 1 / 1,
                                        }"
                                        :resize-image="{
                                            adjustStencil: false
                                        }"
                                        image-restriction="stencil"
                                    ></cropper>
                                </div>
                                <div class="text-center pt-4">
                                    <button type="button" class="btn btn-default" @click.prevent="$refs.upload.clear">Cancel</button>
                                    <button type="submit" class="btn btn-current" @click.prevent="editSave">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import FileUpload from 'vue-upload-component'
import axios from 'axios';
export default {
    props: ['user'],
    components: {
        FileUpload,
        Cropper
    },
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            edit: false,
            changed: false,
            pict: '',
            files:[],
        }
    },
    methods: {
        async savePicture() {
            let data = {
                'photo': this.pict,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/account/profile/change-picture`,data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then((res) => {
                if(res.data.success) {
                    this.$swal({
                        toast: true,
                        title: 'Profile Picture',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.$emit('on-closed');
                    this.$modal.hide('picture-modal');
                }
            }).catch((err) => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Buy Course',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }  
            })
        },
        async editSave() {
            this.edit = false
            this.changed = true
            let oldFile = this.files[0]
            const result = this.$refs.cropper.getResult();
            let binStr = atob(result.canvas.toDataURL(oldFile.type).split(',')[1])
            let arr = new Uint8Array(binStr.length)
            for (let i = 0; i < binStr.length; i++) {
                arr[i] = binStr.charCodeAt(i)
            }
            let file = new File([arr], oldFile.name, { type: oldFile.type })
            this.$refs.upload.update(oldFile.id, {
                file,
                type: file.type,
                size: file.size,
                active: true,
            })
            
            let data = new FormData();
            data.append('dir', 'profile/picture');
            data.append('attachment', this.files[0].file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files[0].response = res.data
                this.pict = res.data.path
                this.edit = false
            }).catch(err => {
                console.log(err.response)
            })
        },

        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(jpg|png|jpeg)$/i.test(newFile.name)) {
                    this.$swal({
                        toast: true,
                        title: 'Profile Picture',
                        text: 'Filetype must be jpg/png/jpeg',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    return prevent()
                }
            }
            if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                newFile.url = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.url = URL.createObjectURL(newFile.file)
                    this.edit = true
                }
            }
        },
    }
}
</script>
<style scoped>
@media only screen and (max-width: 992px) {
    ::v-deep .vm--modal {
        width: 90% !important;
        left: 5% !important;
    }
}
::v-deep .vm--modal {
    width: 600px;
    height: fit-content !important;
}
.cropper {
  height: 600px;
  background: #DDD;
}
</style>