<template>
    <div class="card-body pb-0">
        <div class="row">
            <div class="col-12" v-if="!isLoad">
                <div v-if="!edit">
                    <p class="editable-text font-xssss fw-600 lh-28 text-grey-500 pl-0">{{user.profile.about ? user.profile.about : 'Your profile data is empty!'}}</p>
                    <ul class="d-flex align-items-center mt-2 mb-3 float-left">
                        <li class="mr-2">
                            <a v-if="user.profile.facebook_url" :href="user.profile.facebook_url" target="_blank" class="btn-round-md bg-facebook"><i class="font-xs ti-facebook text-white"></i></a>
                            <button v-else disabled class="btn-round-md bg-facebook"><i class="font-xs ti-facebook text-white"></i></button>
                        </li>
                        <li class="mr-2">
                            <a v-if="user.profile.twitter_url" :href="user.profile.twitter_url" target="_blank" class="btn-round-md bg-twiiter"><i class="font-xs ti-twitter-alt text-white"></i></a>
                            <button v-else disabled class="btn-round-md bg-twiiter"><i class="font-xs ti-twitter-alt text-white"></i></button>
                        </li>
                        <li class="mr-2">
                            <a v-if="user.profile.linkedin_url" :href="user.profile.linkedin_url" target="_blank" class="btn-round-md bg-linkedin"><i class="font-xs ti-linkedin text-white"></i></a>
                            <button v-else disabled class="btn-round-md bg-linkedin"><i class="font-xs ti-linkedin text-white"></i></button>
                        </li>
                        <li class="mr-2">
                            <a v-if="user.profile.instagram_url" :href="user.profile.instagram_url" target="_blank" class="btn-round-md bg-instagram"><i class="font-xs ti-instagram text-white"></i></a>
                            <button v-else disabled class="btn-round-md bg-instagram"><i class="font-xs ti-instagram text-white"></i></button>
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <form @submit.prevent="postBio()" action="" method="post">
                        <div class="form-group">
                            <label class="mont-font fw-600 font-xsss">Your description</label>
                            <textarea v-model="user.profile.about" class="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Write your description..."></textarea>
                        </div>
                        <div class="form-group">
                            <label class="mont-font fw-600 font-xsss">Your Facebook account url</label>
                            <input v-model="user.profile.facebook_url" type="url" name="comment-name" class="form-control mb-2 bg-greylight">
                        </div>
                        <div class="form-group">
                            <label class="mont-font fw-600 font-xsss">Your Twitter account url</label>
                            <input v-model="user.profile.twitter_url" type="url" name="comment-name" class="form-control mb-2 bg-greylight">
                        </div>
                        <div class="form-group">
                            <label class="mont-font fw-600 font-xsss">Your LinkedIn account url</label>
                            <input v-model="user.profile.linkedin_url" type="url" name="comment-name" class="form-control mb-2 bg-greylight">
                        </div>
                        <div class="form-group">
                            <label class="mont-font fw-600 font-xsss">Your Instagram account url</label>
                            <input v-model="user.profile.instagram_url" type="url" name="comment-name" class="form-control mb-2 bg-greylight">
                        </div>
                        <button type="submit" class="bg-current border-0 mb-2 p-2 float-right w175 text-white fw-600 font-xsss rounded-lg text-uppercase">Save</button>
                    </form>
                </div>
            </div>
            <div class="col-12 text-center py-3" v-else>
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
                <img :src="'/images/empty.png'" v-show="dataNotExist" alt="" width="300">
                <h1 class="text-muted" v-show="dataNotExist">Data don't exist</h1>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            isLoad: false,
        }
    },
    props: ['edit', 'user'],
    methods:{
        async postBio(){
            var data = {
                about: this.user.profile.about,
                facebook_url: this.user.profile.facebook_url,        
                twitter_url: this.user.profile.twitter_url,        
                linkedin_url: this.user.profile.linkedin_url,        
                instagram_url: this.user.profile.instagram_url,        
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/account/bio`, data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$emit('data-change')
                this.$swal({
                    toast: true,
                    title: 'Bio Profile!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Bio Profile!',
                    text: 'Failed Update Bio!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        }
    }
}
</script>