<template>
    <div class="middle-sidebar-bottom">
        <div v-if="user">
            <PictureModal :user="user" @on-closed="getUser()"></PictureModal>
        </div>
        <div class="middle-sidebar-left mb-5" v-if="!isLoad">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-3" >
                <div class="card-body p-lg-5 p-4 bg-black-08">
                    <div class="clearfix"></div>
                    <div class="row justify-content-center">
                        <div class="col-lg-11 pl-xl-5 pt-xl-5">
                            <figure class="avatar ml-0 mb-4 position-relative w100 z-index-1 cursor-pointer" @click="pictModal()"><img :src="user.photo" alt="image" class="float-right p-1 bg-white rounded-circle w-100"></figure>
                        </div>
                        <div class="col-xl-4 col-lg-6 pl-xl-5 pb-xl-5 pb-3">
                            <h4 class="fw-700 font-md text-white mt-3 mb-1">{{user.name}} <i v-if="user.role_id = 1" class="ti-check font-xssss btn-round-xs bg-success text-white ml-1"></i></h4>
                            <span class="font-xssss fw-600 text-grey-500 d-inline-block ml-0">{{user.email}}</span>
                            <span class="dot mx-2 d-inline-block btn-round-xss bg-grey"></span>
                            <span class="font-xssss fw-600 text-grey-500 d-inline-block ml-0">@{{user.username}}</span>
                        </div>
                        <div class="col-xl-4 col-lg-6 d-block">
                            <!-- <h2 class="display5-size text-white fw-700 lh-1 mr-3">98 <i class="feather-arrow-up-right text-success font-xl"></i></h2>
                            <h4 class="text-white font-sm fw-600 mt-0 lh-3">Your learning level points! </h4> -->
                        </div>
                        <div class="col-xl-3 mt-4">
                            <div id="chart-users-blue3" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-between product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="active list-inline-item"><a class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active" href="#navtabs0" data-toggle="tab">Bio</a></li>
                    <li class="list-inline-item"><a class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs1" data-toggle="tab">Profile</a></li>
                    <li class="list-inline-item"><a class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs2" data-toggle="tab">Course</a></li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="navtabs0">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Bio</b><button @click="edit_bio = !edit_bio" class="float-right border-0 bg-white"><i class="feather-edit text-grey-500 font-xs"></i></button></h2></div>
                        <Bio @data-change="this.getUser()" :edit="edit_bio" :user="user" />
                    </div>
                </div>
                <div class="tab-pane fade" id="navtabs1">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Profile</b> <button @click="edit_profile = !edit_profile" class="float-right border-0 bg-white"><i class="feather-edit text-grey-500 font-xs"></i></button></h2></div>
                        <Profile @data-change="this.getUser()" :edit="edit_profile" :user="user" />
                    </div>
                </div>
                <div class="tab-pane fade" id="navtabs2">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Courses</b> <a href="#" class="float-right"><i class="feather-edit text-grey-500 font-xs"></i></a></h2></div>
                        <Course @data-change="this.getUser()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-sidebar-left mb-5" v-else>
            <div class="row">
                <div class="col-12 text-center py-2">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Bio from './Part/Bio.vue'
import Profile from './Part/Profile.vue'
import Course from './Part/Course.vue'
import PictureModal from '../../../components/helpers/PictureModal.vue'
export default {
    components:{
        Bio,
        Profile,
        Course,
        PictureModal
    },
    data(){
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            user: null,
            isLoad: true,
            edit_bio : false,
            edit_profile : false,
        }
    },
    created() {
        this.getUser()
    },
    methods: {
        pictModal() {
            if(this.user) {
                this.$modal.show('picture-modal')
            }
        },
        async getUser() {
            await axios.get(`${process.env.VUE_APP_URL_API}/user`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.user = res.data;
                this.isLoad = false
            }).catch((err) => console.error(err))
        }
    }
};
</script>