<template>
    <div class="card-body p-3 d-block text-left">
        <div class="row">
            <div class="col-12" v-if="!edit">
                <h2 class="pl-35 mb-5 font-xsss fw-600 text-grey-900 position-relative border-bottom"><i class="fas fa-venus-mars font-sm text-current position-absolute left-0"></i> Gender 
                    <span class="d-block text-grey-500 mt-1 font-xsss">{{user.profile.gender ? user.profile.gender : 'Unkown'}}</span>
                </h2>
                <h2 class="pl-35 mb-5 font-xsss fw-600 text-grey-900 position-relative border-bottom"><i class="feather-gift font-lg text-current position-absolute left-0"></i> Birthday 
                    <span class="d-block text-grey-500 mt-1 font-xsss">{{user.profile.birth_date ? user.profile.birth_date : 'Unkown'}}</span>
                </h2>
                <h2 class="pl-35 mb-5 font-xsss fw-600 text-grey-900 position-relative border-bottom"><i class="fas fa-layer-group font-sm text-current position-absolute left-0"></i> University 
                    <span class="d-block text-grey-500 mt-1 font-xsss">{{user.profile.university ? user.profile.university : 'Unkown'}}</span>
                </h2>
                <h2 class="pl-35 mb-5 font-xsss fw-600 text-grey-900 position-relative border-bottom"><i class="feather-phone font-lg text-current position-absolute left-0"></i> Phone Number 
                    <span class="d-block text-grey-500 mt-1 font-xsss">{{user.profile.phone ? user.profile.phone : 'Unkown'}}</span>
                </h2>
            </div>
            <div v-else class="col-12">
                <form @submit.prevent="postProfile()" action="" method="post">
                    <div class="form-group">
                        <label class="mont-font fw-600 font-xsss">Your Gender</label>
                        <select v-model="user.profile.gender" class="form-control mb-2" required>
                            <option value="" selected disabled>-- Select Gender --</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="mont-font fw-600 font-xsss">Your Birthday</label>
                        <input v-model="user.profile.birth_date" type="date" class="form-control mb-2">
                    </div>
                    <div class="form-group">
                        <label class="mont-font fw-600 font-xsss">Your University</label>
                        <input v-model="user.profile.university" type="text" class="form-control mb-2">
                    </div>
                    <div class="form-group mb-0">
                        <label class="mont-font fw-600 font-xsss">Your Phone Number</label>
                        <!-- <input v-model="user.profile.phone" type="number" class="form-control mb-2 bg-greylight"> -->
                    </div>
                    <div class="form-group icon-input mb-1">
                        <span class="font-xsss fw-600 text-grey-700 pr-0">+62</span>
                        <input type="tel" min="11" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" required class="style2-input pl-5 form-control text-grey-900 font-xsss fw-600" placeholder="Your Phone Number" v-model="phone">                        
                    </div>
                    <button type="submit" class="bg-current border-0 mb-2 p-2 float-right w175 text-white fw-600 font-xsss rounded-lg text-uppercase">Save</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            isLoad: false,
            phone: '',
        }
    },
    props: ['edit', 'user'],
    mounted() {
        if(this.user.profile && this.user.profile.phone) {
            this.phone = this.user.profile.phone.replace('+62','')
        }
    },
    methods:{
        async postProfile(){
            var data = {
                gender: this.user.profile.gender,
                birth_date: this.user.profile.birth_date,        
                university: this.user.profile.university,        
                phone: '+62'+this.phone,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/account/profile`, data,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$emit('data-change')
                if(this.phone) {
                    this.user.profile.phone = '+62'+this.phone
                }
                this.$swal({
                    toast: true,
                    title: 'Profile!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Profile!',
                    text: 'Failed Update Profile!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        }
    }
}
</script>
<style scoped>
.form-group.icon-input span {
    position: absolute;
    left: 15px;
    top: 18.5px;
}
</style>