var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"picture-modal","adaptive":true,"scrollable":true,"height":'auto'}},[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header py-3 bg-white border-0"},[_c('a',{staticClass:"float-right",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$modal.hide('picture-modal')}}},[_c('i',{staticClass:"fas fa-times text-danger"})]),_c('h1',{staticClass:"fredoka-font ls-3 fw-700 text-current-eling font-lg mb-0"},[_vm._v("Elingway")])]),_c('div',{staticClass:"card-body py-5 py-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.edit),expression:"!edit"}],staticClass:"avatar-upload"},[_c('div',{staticClass:"text-center p-2"},[_c('label',{attrs:{"for":"avatar"}},[_c('img',{attrs:{"width":"200","src":_vm.files.length ? _vm.files[0].url : _vm.user.photo}})])]),_c('div',{staticClass:"text-center p-2"},[_c('file-upload',{ref:"upload",staticClass:"btn btn-current",attrs:{"extensions":"jpg,jpeg,png","accept":"image/png,image/jpeg,image/jpg","name":"avatar","drop":!_vm.edit,"required":""},on:{"input-filter":_vm.inputFilter},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},[_vm._v(" Upload Picture ")]),(_vm.changed)?_c('button',{staticClass:"btn btn-success ml-2",on:{"click":function($event){return _vm.savePicture()}}},[_vm._v("Save Picture")]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.files.length && _vm.edit),expression:"files.length && edit"}],staticClass:"avatar-edit"},[(_vm.files.length)?_c('div',{staticClass:"avatar-edit-image"},[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.files[0].url,"stencil-props":{
                                        handlers: {},
                                        movable: false,
                                        scalable: false,
                                        aspectRatio: 1 / 1,
                                    },"resize-image":{
                                        adjustStencil: false
                                    },"image-restriction":"stencil"}})],1):_vm._e(),_c('div',{staticClass:"text-center pt-4"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.upload.clear.apply(null, arguments)}}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-current",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.editSave.apply(null, arguments)}}},[_vm._v("Save")])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }